import React, { Fragment, useEffect } from 'react'
import ArticleCard from '../../components/ArticleCard'
import { Mobile, MobileElse } from 'ion-media'
import MagazineCover from '../../components/MagazineCover'
import { useLocation } from 'react-router-dom'

const Default = ({ articles, section, noImage, isConnected, moreButton }) => {
  const location = useLocation()
  useEffect(() => {
    if (typeof window !== 'undefined' && window.adngin) {
      window.adngin.queue.push(function () {
        // trigger a new auction
        window.adngin.cmd.startAuction(['leaderboard-2', 'instream', 'adhesive'])
      })
    }
  }, [location.pathname])
  return (
    <>
      {articles.map((article, index) => (
        <Fragment key={article.contentKey}>
          {index <= 10 && (
            <ArticleCard article={article} noImage={noImage} />
          )}
          <MobileElse>
            {(index % 18 === 0) && (index > 15) && (
              <div id='adngin-instream-0' className='advert-in-article' />
            )}
          </MobileElse>
          <Mobile>
            {(index === 2) && (<MagazineCover />)}
          </Mobile>
          {index > 10 && (
            <ArticleCard article={article} noImage={noImage} lazy />
          )}
          <MobileElse>
            {index === 8 && (
              <div id='adngin-leaderboard-2-0' className='advert-leaderboard-2' />
            )}
          </MobileElse>
          {Number.isInteger((index + 5) / 4) && (
            <Mobile>
              <article className='advert'>
                <div id='adngin-instream-0' className='advert-in-article' />
              </article>
            </Mobile>
          )}
        </Fragment>
      ))}
      {moreButton}
    </>
  )
}

export default Default
