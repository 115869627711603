import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { SectionMeta } from 'ion-article-cmp'
import WingBanners from '../components/WingBanners'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { fetchAuthors } from '../../store/authors'
import { getSectionLabel } from 'ion-sections'
import { MobileElse, DesktopTablet } from 'ion-media'
import Sidebar from '../components/Sidebar'
import NoImage from '../static/no-image.png'
import MagazineCover from '../components/MagazineCover'
import AuthorBio from '../components/AuthorBio'

class AuthorsSectionPage extends (React.Component) {
  constructor (props) {
    super(props)
    this.state = { hasMounted: false }
    if (!this.props.authors.hasFetched) {
      props.fetchAuthors()
    }
  }

  componentDidMount () {
    this.setState({ hasMounted: true })
    if (typeof window !== 'undefined' && window.adngin) {
      window.adngin.queue.push(function () {
        // trigger a new auction
        window.adngin.cmd.startAuction(['leaderboard-2', 'leaderboard-3', 'wingbanner-left', 'wingbanner-right', 'halfpage-1', 'mpu-1', 'adhesive'])
      })
    }
  }

  render () {
    const props = this.props
    return (
      <>
        <Helmet
          titleTemplate='%s'
          title={props.title || getSectionLabel(props.section)}
        >
          <meta property='og:type' content='section' />
          <meta name='description' content={props.description} />
          <meta itemProp='description' content={props.description} />
          <meta property='og:description' content={props.description} />
          <meta property='og:url' content={props.url} />
          <meta property='keywords' content={props.keywords} />
          <meta name='twitter:description' content={props.description} />
        </Helmet>
        <WingBanners useSmall={props.useSmall} />
        <div id='adngin-leaderboard-1-0' className='advert-leaderboard-1' />
        <div className='wrapper article-listing'>
          {props.authors && props.authors.authors &&
            <div className='author article-list'>
              <div className='author-bio no-divider'>
                <h1>Authors</h1>
                <p>Follow your favourite authors here to be notified of all their latest articles.</p>
                <p>This option is not available to all web browsers.</p>
              </div>
              {
                props.authors.authors.map((element, index) => {
                  return (
                    <AuthorBio key={index} {...element} noImage={NoImage} />
                  )
                })
              }
            </div>}
          {this.state.hasMounted
            ? (
              <Sidebar {...props}>
                <DesktopTablet>
                  <MagazineCover />
                </DesktopTablet>
              </Sidebar>
              )
            : (
              <Sidebar {...props} />
              )}
        </div>
        <MobileElse>
          <div id='adngin-leaderboard-3-0' className='advert-leaderboard-3' />
        </MobileElse>
        <SectionMeta {...props} />
      </>
    )
  }
}

AuthorsSectionPage.defaultProps = {
  section: 'homepage'
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchAuthors }, dispatch)

export default withRouter(connect((state) => ({ authors: state.authors }), mapDispatchToProps)(AuthorsSectionPage))
